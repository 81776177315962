import React, { useEffect, useState } from 'react'
import { DialogWrapper } from '../modal-dialog/dialog-wrapper'
import { ModalDialog } from '../modal-dialog/modal-dialog'
import { useTranslation } from 'react-i18next'
import { ScaleButton } from '@telekom/scale-components-react'
import styles from './Radar.module.scss'
import FundMapped from '../../model/FundMapped'
import RadarSectorFundsDataTable from './RadarSectorFundsDataTable'

interface RadarSectorFundsModalProps {
  onModalClose: () => void,
  stageName?: string | string[],
  sectorName?: string,
  currentFunds?: FundMapped[]
}

export const RadarSectorFundsModal = ({ onModalClose, stageName = '', sectorName = '', currentFunds = [] }: RadarSectorFundsModalProps) => {

  const [basicFunds, setBasicFunds] = useState<FundMapped[]>([]);
    console.log(sectorName)

  useEffect(() => {
    document.getElementsByClassName('App')[0].classList.add('with-modal-opened');
    const filteredFunds = currentFunds.filter(fund => {
        const parentStreamFunds = fund.parent_stream_name === sectorName && (stageName.indexOf(fund.maturity) >= 0 || stageName === fund.maturity)
        const primaryStreamFunds = fund.primaryStream === sectorName && (stageName.indexOf(fund.maturity) >= 0 || stageName === fund.maturity)
        // console.log(fund.parent_stream_name)
        return parentStreamFunds || primaryStreamFunds
    });
    setBasicFunds(filteredFunds);
  }, [])

  const { t } = useTranslation("BOFundTranslationKeys");

  const sortObjectsByField = (fieldName: string, ascending = true) => {
    if (!Array.isArray(basicFunds) || basicFunds.length === 0) {
        return;
    }

    const compareFunction = (a: FundMapped, b: FundMapped) => {
        const aValue = a[fieldName as keyof FundMapped] || 0;
        const bValue = b[fieldName as keyof FundMapped] || 0;

        if (aValue < bValue) {
            return ascending ? -1 : 1;
        } else if (aValue > bValue) {
            return ascending ? 1 : -1;
        } else {
            return 0;
        }
    };

    // Use the compare function to sort the array
    const sortedArray = basicFunds.slice().sort(compareFunction);
    setBasicFunds(sortedArray);
}


  const modalContent = (
    <>
      <div className={styles.modalHeader}>
        <div className={styles.modalTitle}>
          {sectorName}
          <div className={styles.modalSubTitle}>{t("BO:BO-FP-RFPPhase")}: {Array.isArray(stageName) ? (stageName as string[]).join(' & ') : stageName} {'('}{basicFunds.length}{')'}</div>
        </div>
        <div className={styles.modalClose} onClick={() => { closeDialog() }} tabIndex={0}>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 27.94" >
            <path fill='currentColor' d="M30,28.42a2.06,2.06,0,0,0-.66-1.49L19,16.57,29.34,6.2A2,2,0,0,0,30,4.71a2.45,2.45,0,0,0-.78-1.66,2,2,0,0,0-1.37-.42,2,2,0,0,0-1.49.6L16,13.59,5.63,3.23a2,2,0,0,0-1.49-.6,2.09,2.09,0,0,0-1.51.6A1.91,1.91,0,0,0,2,4.68,2.06,2.06,0,0,0,2.66,6.2L13,16.57,2.66,26.93A2.06,2.06,0,0,0,2,28.42a2.46,2.46,0,0,0,.78,1.67,2,2,0,0,0,1.36.42,2,2,0,0,0,1.49-.6L16,19.55,26.36,29.91a2.37,2.37,0,0,0,1.49.66,1.72,1.72,0,0,0,.78-.18A2,2,0,0,0,30,28.42Z" transform="translate(-2 -2.63)" />
          </svg>
        </div>
      </div>
      <div className={styles.modalBody}>
        <RadarSectorFundsDataTable data={basicFunds}
                                    isZuteilungMode={stageName === 'Zuteilung'}
                                    onSortingAction={params => {
                                    const isAscending = params.charAt(0) === '-' ? false : true;
                                    const sortParam = params.substring(1)
                                    sortObjectsByField(sortParam, isAscending)
                                  }}
        /> 
      </div>
      <div className="modal__actions">
        <ScaleButton slot="action" variant="ghost" onClick={() => closeDialog()}>
          {t("details:modalActionInterrupt")}
        </ScaleButton>
      </div>
    </>
  )

  const closeDialog = () => {
    setTimeout(() => {
      document.getElementsByClassName('App')[0].classList.remove('with-modal-opened');
      document.getElementsByClassName('dialog-container')[0].remove();
    });

    onModalClose();
  }

  return (
    <DialogWrapper idParam={'modal-root'}>
      <ModalDialog
        type={'info'}
        formContent={modalContent}
        isOpened={true}
        isWideModal={true}
        isHighModal={true}
      />
    </DialogWrapper>)

}