import {ScaleTable} from "@telekom/scale-components-react";
import React, {ReactNode} from "react";
import FundMapped from "../../model/FundMapped";
import DetailHyperlink from "../../model/DetailHyperlink";
import ObjectUtil from "../../utils/ObjectUtil";
import SortingIcons from '../SortingIcons/SortingIcons'
import WidthDependent, {BreakpointDependentRenderer as BreakpointRenderer} from "../WidthDependent/WidthDependent";
import {BREAKPOINTS} from "../../theme/styles/Breakpoints"

import { withTranslation, Translation } from 'react-i18next'
import i18next from "i18next";
import styles from './Radar.module.scss'

import '../../components/DataTable/data-table.scss';

import {ReactComponent as DefaultIcon} from "../../icons/default-fund.svg";
import {ReactComponent as DeadlineIcon} from "../../icons/deadline-fund.svg";
import {ReactComponent as UpdateIcon} from "../../icons/update-fund.svg";
import {ReactComponent as NewIcon} from "../../icons/new-fund.svg";
import { HIGHLIGHT } from '../../constants/BEstatuses'
// import {ReactComponent as ErrorIcon} from "../../icons/error_placeholder.svg";

interface Props {
    data: FundMapped[],
    onSortingAction: (params: string) => void,
    isZuteilungMode?: boolean,
}

interface State {
    params: string,
    showTooltip?: string
}

class RadarSectorFundsDataTable extends React.Component<Props, State> {

    currentOrderBy:string = '';

    constructor(props: Props) {
        super(props);

        this.onSortingAction = this.onSortingAction.bind(this);
        this.onSortingAction(this.currentOrderBy)

    }
    
    public renderTableRowsAllocation(basicFunds: FundMapped[]): ReactNode {
        return basicFunds.map((item, i) => {
            const hyperlink = DetailHyperlink.createHyperlinkForDetailPage(item.id)
            return <tr id={item.id} key={item.id + item.name + '_key'}>
                <td className='fund-name column column--large'><a className="link" title={item.name} href={hyperlink.href} target='_self'
                    rel="noreferrer">{item.name}</a>
                </td>
                <td className='stream-name column column--medium'>{item.primaryStream}</td>
                <td className='teritorial-coverage column column--medium column--no-wrap'>
                    {item.geoArea && item.geoArea.map(area =>
                        <div key={area.id} className="publication-item publication-item--title bold">
                            {area.name}
                        </div>
                    )}
                </td>
                <td className='column column--medium column--no-wrap'>{item.fundingAmount}</td>
                <td className='aplication-period column column--medium'>{item.deadline && ObjectUtil.formatDateObj(item.deadline)}</td>
            </tr>
        });
    }

    public renderTableHeaderZuteilung(): ReactNode {
        return <tr className={styles.modalBodyTableHeader}>
            <th className='fund-name column column--large'>
                <SortingIcons sortParamName='name' sortParams={this.currentOrderBy} onSortingAction={this.onSortingAction}/>
                <span className="title"><Translation>{ (t) => t("details:fundingName")}</Translation></span>
            </th>
            <th className='stream-name column column--medium'>
                <SortingIcons sortParamName='primaryStream' sortParams={this.currentOrderBy} onSortingAction={this.onSortingAction}/>
                <span className="title"><Translation>{ (t) => t("details:primaryStream")}</Translation></span>
            </th>
            <th className='teritorial-coverage column column--medium' title={String(i18next.t("details:geoArea"))}>
                <SortingIcons sortParamName='geoArea' sortParams={this.currentOrderBy} onSortingAction={this.onSortingAction}/>
                <span className="title"><Translation>{ (t) => t("F:RP-F-FPArea")}</Translation></span></th>
            <th className='column column--medium'>
                <SortingIcons sortParamName='fundingAmount' sortParams={this.currentOrderBy} onSortingAction={this.onSortingAction}/>
                <span className="title"><Translation>{ (t) => t("details:fundingAmount")}</Translation></span></th>
            <th className='aplication-period column column--medium' title={String(i18next.t("details:deadline"))}>
                <SortingIcons sortParamName='deadline' sortParams={this.currentOrderBy} onSortingAction={this.onSortingAction}/>
                <span className="title"><Translation>{ (t) => t("F:RP-D-FPDeadline")}</Translation></span>
            </th>
        </tr>
    }


    public renderTableRows(basicFunds: FundMapped[]): ReactNode {
        return basicFunds.map((item, i) => {
            const hyperlink = DetailHyperlink.createHyperlinkForDetailPage(item.id)
            return <tr id={item.id} key={item.id + i + '_key'}>
                <td className='column column--small' >
                    <div className="publication-item publication-item--icon" style={{ display: 'inline-block' }}>
                        {RadarSectorFundsDataTable.renderStatusDiamond(item.status)}
                    </div>
                </td>
                <td className='fund-name column column--large'><a className="link" title={item.name} href={hyperlink.href} target='_self'
                    rel="noreferrer">{item.name}</a>
                </td>
                <td className='teritorial-coverage column column--medium column--no-wrap'>
                    {item.geoArea && item.geoArea.map(area =>
                        <div key={area.id} className='publication-item publication-item--title bold'>
                            {area.name}
                        </div>,
                    )}
                </td>
                <td className='column column--medium column--no-wrap'>{item.fundingAmount}</td>
                <td className='aplication-period column column--medium'>{item.deadline && ObjectUtil.formatDateObj(item.deadline)}</td>
            </tr>
        });
    }

    public renderTableHeader(): ReactNode {
        return <tr className={styles.modalBodyTableHeader}>
            <th className='status-column column column--small' >
                <SortingIcons sortParamName='status' sortParams={this.currentOrderBy} onSortingAction={this.onSortingAction}/>
                <span className="title"><Translation>{ (t) => t("details:status")}</Translation></span>
            </th>
            <th className='fund-name column column--large'>
                <SortingIcons sortParamName='name' sortParams={this.currentOrderBy} onSortingAction={this.onSortingAction}/>
                <span className="title"><Translation>{ (t) => t("details:fundingName")}</Translation></span>
            </th>
            <th className='teritorial-coverage column column--medium' title={String(i18next.t("details:geoArea"))}>
                <SortingIcons sortParamName='geoArea' sortParams={this.currentOrderBy} onSortingAction={this.onSortingAction}/>
                <span className="title"><Translation>{ (t) => t("F:RP-F-FPArea")}</Translation></span></th>
            <th className='column column--medium'>
                <SortingIcons sortParamName='fundingAmount' sortParams={this.currentOrderBy} onSortingAction={this.onSortingAction}/>
                <span className="title"><Translation>{ (t) => t("details:fundingAmount")}</Translation></span></th>
            <th className='aplication-period column column--medium' title={String(i18next.t("details:deadline"))}>
                <SortingIcons sortParamName='deadline' sortParams={this.currentOrderBy} onSortingAction={this.onSortingAction}/>
                <span className="title"><Translation>{ (t) => t("F:RP-D-FPDeadline")}</Translation></span>
            </th>
        </tr>
    }

    public renderDesktopView(headerRows: ReactNode, tableRows: ReactNode) {
        return (
            <ScaleTable size={"medium"}className={styles.modalBodyTable}>
                <table>
                    <thead>
                        {headerRows}
                    </thead>
                    <tbody>
                        {tableRows}
                    </tbody>
                </table>
            </ScaleTable>
        )
    }

    private static renderStatusDiamond(status: string | undefined): ReactNode {

        if (status === HIGHLIGHT.NO_CHANGE || status === undefined) {
            return <DefaultIcon />
        } else if (status === HIGHLIGHT.NOTE_DEADLINES) {
            return <DeadlineIcon />
        } else if (status === HIGHLIGHT.CONTENT_UPLOADED) {
            return <UpdateIcon />
        } else if (status === HIGHLIGHT.NEW_FUNDING_PROGRAM) {
            return <NewIcon />
        }
    }

    onSortingAction(orderBy: string) {
        this.props.onSortingAction && this.props.onSortingAction(orderBy)
    }

    render() {
        const headerRows = this.props.isZuteilungMode ? this.renderTableHeaderZuteilung() : this.renderTableHeader();
        const tableRows = this.props.isZuteilungMode ? this.renderTableRowsAllocation : this.renderTableRows;

        /* breakpoints */
        const breakpointRenderers: BreakpointRenderer[] = [
            {
                breakpoint: BREAKPOINTS.tabletMax + 1, renderer: () => {
                    return this.renderDesktopView(headerRows, tableRows(this.props.data))
                }
            },
            {
                breakpoint: BREAKPOINTS.desktop + 1, renderer: () => {
                    return this.renderDesktopView(headerRows, tableRows(this.props.data))
                }
            },
        ]
        return (
            <div className="data-table-container" style={{ margin: 0 }}>
                <WidthDependent renderers={breakpointRenderers} />
            </div>
        )

    }
}

export default withTranslation("details")(RadarSectorFundsDataTable)